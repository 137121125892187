import React, { useState, useEffect } from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { Flex } from 'rebass';
import moment from 'moment';
import PropTypes from 'prop-types';
import Icon from '~common/atoms/Icon';

import CheckEfc from '~common/molecules/CheckEfc';
import useFeatureToggle from '~lib/hooks/useFeatureToggle';
import { useThemeUI } from 'theme-ui';

const Mask = styled.div`
  background-color: ${props => props.theme.colors.white};
  position: fixed;
  top: 260px;
  left: 0px;
  opacity: 0.8;
  height: 100%;
  width: 100%;
  @media all and (max-width: 768px) {
    width: 0;
  }
`;

const PanelContainer = styled.div`
  position: fixed;
  padding: 5px 0px 30px;
  width: 100%;
  top: 0px;
  left: 0px;
  background-color: #f7f7f7;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.3);
  z-index: 2;
  @media all and (max-width: 768px) {
    height: 100%;
    overflow: scroll;
    padding: 0px;
  }
`;

const InnerFlexBox = styled.div`
  display: flex;
  align-items: start;
  @media all and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    label {
      padding: 20px; 0px;
    }
  }
`;

const DropdownMenu = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.primary};
  margin: 0 10px 0 5px;
`;

const SelectMenu = styled.select`
  border: none;
  outline: none;
  background-color: white;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  font-size: ${props => props.theme.fontSizes[2]}px;
  background: url(/images/chevron-down-blue.svg) no-repeat right;
  margin: -5px 10px 10px;
  padding-right: 30px;
  background-size: 16px;
  color: ${props => props.theme.colors.primary};
  // for IE 10+
  &::-ms-expand {
    display: none;
  }
`;

const InputButton = styled.input`
  color: ${props => props.theme.colors.white};
  padding: 8px 16px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  font-weight: 700;
  font-size: ${props => props.theme.fontSizes[2]}px;
  background-color: ${props => props.theme.colors.primary};
  border: 2px solid ${props => props.theme.colors.primary};
  border-radius: 5px;
  line-height: 35px;
  min-width: 180px;
  @media all and (max-width: 768px) {
    padding: 8px 16px;
    min-width: 160px;
  }
  @media all and (max-width: 350px) {
    padding: 6px 16px;
    min-width: 140px;
  }
`;

const statusValues = ['Single', 'Couple', 'Family', 'Single Parent'];

const regionValues = ['NSW', 'VIC', 'QLD', 'ACT', 'WA', 'SA', 'NT', 'TAS'];

const tierValues = [
  {
    options: [
      {
        description: 'Base tier',
        label: 'Below $97k',
        value: '0',
      },
      {
        description: 'Tier 1',
        label: '$97k - $113k',
        value: '1',
      },
      {
        description: 'Tier 2',
        label: '$113k - $151k',
        value: '2',
      },
      {
        description: 'Tier 3',
        label: 'Over $151k',
        value: '3',
      },
    ],
  },
  {
    options: [
      {
        description: 'Base tier',
        label: 'Below $194k',
        value: '0',
      },
      {
        description: 'Tier 1',
        label: '$194k - $226k',
        value: '1',
      },
      {
        description: 'Tier 2',
        label: '$226k - $302k',
        value: '2',
      },
      {
        description: 'Tier 3',
        label: 'Over $302k',
        value: '3',
      },
    ],
  },
];

const QuoteFilterForm = ({
  form,
  isFilterOpen,
  toggleFilterPanel,
  setForm,
}) => {
  const [statusState, updateStatus] = useState(form.status.label);
  const [regionState, updateRegion] = useState(form.region.value);
  const [dobState] = useState(form.dob.value);
  const [tierState, updateTier] = useState(form.tier.label);
  const [tierValueState, updateTierValue] = useState(form.tier.value);
  const [efc, setEfc] = useState(form.efc && form.efc.value);
  const { theme } = useThemeUI();

  useEffect(() => {
    if (isFilterOpen) {
      document.getElementById('___gatsby').style.cssText =
        'position: fixed; overflow: hidden;';
    } else {
      document.getElementById('___gatsby').style.cssText =
        'position: relative; overflow: auto;';
    }
  }, [isFilterOpen]);

  const currentTierValue = tierLabel => {
    switch (tierLabel) {
      case 'Below $97k':
      case 'Below $194k':
        return 0;
      case '$97k - $113k':
      case '$194k - $226k':
        return 1;
      case '$113k - $151k':
      case '$226k - $302k':
        return 2;
      case 'Over $151k':
      case 'Over $302k':
        return 3;
      default:
        return 0;
    }
  };

  const { efcQuote } = useFeatureToggle();

  const isEfcEligible =
    efcQuote && ['Family', 'Single Parent'].includes(statusState);

  const handleUpdate = () => {
    const singleOptionsTier = tierValues[0].options.find(
      option => option.label === tierState
    );
    const nonSingleOptionsTier = tierValues[1].options.find(
      option => option.label === tierState
    );

    setForm({
      ...form,
      efc: {
        ...form.efc,
        value: isEfcEligible && efc,
        label: isEfcEligible && efc ? 'Yes' : 'No',
      },
      paymentFrequency: 'MONTHLY',
      resultType: form.resultType,
      status: {
        value:
          statusState === 'Single Parent'
            ? 'SINGLE_PARENT'
            : statusState.toUpperCase(),
        label: statusState,
        icon: `status-${statusState}`,
      },
      region: {
        label: regionState,
        value: regionState,
      },
      dob: {
        value: dobState,
        label: moment(form.dob.value, 'YYYY-MM-DD').format('Do MMMM YYYY'),
        age: moment().diff(form.dob.value, 'years'),
      },
      tier:
        singleOptionsTier !== undefined
          ? singleOptionsTier
          : nonSingleOptionsTier,
    });

    setTimeout(() => {
      toggleFilterPanel(true);
    }, 100);
  };

  const cancel = () => {
    toggleFilterPanel(true);
    updateStatus(form.status.label);
    updateRegion(form.region.value);
    updateTier(form.tier.label);
    updateTierValue(form.tier.value);
    setEfc(form.efc && form.efc.value);
  };

  return (
    isFilterOpen && (
      <>
        <PanelContainer data-id="edit-quote-panel">
          <Flex
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="start"
            css={css`
              display: block;
              margin: 5px auto 0px;
              max-width: 970px;
              @media all and (max-width: 768px) {
                padding: 10px 15px;
                margin-top: 0px;
                max-width: 100%;
              }
            `}
          >
            <Flex
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <h3
                css={css`
                  font-size: 21px;
                  font-weight: bold;
                  font-style: normal;
                  font-stretch: normal;
                  line-height: 2.19;
                  letter-spacing: normal;
                `}
              >
                {window.innerWidth > 768 ? 'Quick Quote' : 'Your Details'}
              </h3>
              <Icon
                data-id="close-edit-mode"
                onClick={() => toggleFilterPanel(true)}
                name="circle-cross"
                fill={theme.colors.secondary}
                width="27px"
                height="27px"
              />
            </Flex>
            <Flex
              flexDirection="row"
              justifyContent="start"
              alignItems="normal"
              css={css`
                padding: 8px 0px 20px;
                @media all and (max-width: 768px) {
                  flex-direction: column;
                  padding-top: 0px;
                }
              `}
            >
              <InnerFlexBox>
                <label htmlFor="status">Based on a </label>
                <DropdownMenu>
                  <SelectMenu
                    css={css`
                      background-position-x: right;
                      background-position-y: 3px;
                      text-align-last: center;
                      text-transform: lowercase;
                    `}
                    name="status"
                    value={statusState}
                    data-value={statusState}
                    onChange={event => {
                      if (
                        (statusState === 'Single' &&
                          event.target.value === 'Family') ||
                        (statusState === 'Single' &&
                          event.target.value === 'Couple') ||
                        (statusState === 'Single' &&
                          event.target.value === 'Single Parent') ||
                        (statusState === 'Family' &&
                          event.target.value === 'Single') ||
                        (statusState === 'Single Parent' &&
                          event.target.value === 'Single') ||
                        (statusState === 'Couple' &&
                          event.target.value === 'Single')
                      ) {
                        updateTierValue(0);
                        if (statusState === 'Single') {
                          updateTier('Below 97k');
                        } else {
                          updateTier('Below $194k');
                        }
                      }

                      updateStatus(event.target.value);
                    }}
                  >
                    {statusValues.map((type, index) => (
                      <option
                        style={{ textTransform: 'capitalize' }}
                        key={`status_${index}`}
                        value={type}
                      >
                        {type}
                      </option>
                    ))}
                  </SelectMenu>
                </DropdownMenu>
              </InnerFlexBox>
              <InnerFlexBox
                css={css`
                  @media all and (max-width: 768px) {
                    padding: 20px 0px;
                  }
                `}
              >
                <label htmlFor="region">living in</label>
                <DropdownMenu>
                  <SelectMenu
                    css={css`
                      background-position-x: right;
                      background-position-y: 3px;
                    `}
                    name="region"
                    value={regionState}
                    data-value={regionState}
                    onChange={event => {
                      updateRegion(event.target.value);
                    }}
                  >
                    {regionValues.map((type, index) => (
                      <option key={`region_${index}`} value={type}>
                        {type}
                      </option>
                    ))}
                  </SelectMenu>
                </DropdownMenu>
              </InnerFlexBox>
              <InnerFlexBox>
                <label htmlFor="tier">
                  {statusState === 'Single' || statusState === 'Single Parent'
                    ? 'with an annual income of '
                    : 'with a combined annual income of '}
                </label>
                <DropdownMenu style={{ borderBottom: 'none' }}>
                  <SelectMenu
                    css={css`
                      background-position-x: right;
                      background-position-y: 3px;
                      text-transform: lowercase;
                    `}
                    name="tier"
                    value={tierState}
                    data-value={tierState}
                    onChange={event => {
                      updateTierValue(currentTierValue(event.target.value));
                      updateTier(event.target.value);
                    }}
                  >
                    {statusState !== 'Single'
                      ? tierValues[1].options.map((tier, index) => (
                          <option key={`tier_${index}`} value={tier.label}>
                            {tier.label}
                          </option>
                        ))
                      : tierValues[0].options.map((tier, index) => (
                          <option key={`tier_${index}`} value={tier.label}>
                            {tier.label}
                          </option>
                        ))}
                  </SelectMenu>
                  <p
                    css={css`
                      border-top: 1px solid ${theme.colors.primary};
                      font-size: 10pt;
                      padding-top: 8px;
                      @media all and (max-width: 768px) {
                        text-align: center;
                      }
                    `}
                  >
                    {tierValueState === 0
                      ? '(basic tier rebate)'
                      : `(tier ${tierValueState} rebate)`}
                  </p>
                </DropdownMenu>
              </InnerFlexBox>
            </Flex>
            {isEfcEligible && (
              <CheckEfc
                value={efc}
                onChange={setEfc}
                mb={3}
                mt={{
                  xs: 3,
                  md: 0,
                }}
              />
            )}
            <Flex
              css={css`
                padding: 20px 0px;
                @media all and (max-width: 768px) {
                  dispaly: flex;
                  justify-content: center;
                  position: fixed;
                  bottom: 10px;
                  left: 0px;
                  text-align: center;
                  width: 100%;
                }
              `}
            >
              <InputButton
                data-id="recalculate"
                type="button"
                value="Recalculate"
                onClick={handleUpdate}
              />
              <InputButton
                css={css`
                  margin-left: 12px;
                  background-color: white;
                  color: ${theme.colors.primary};
                  padding: 5px 10px;
                  border: 2px solid ${theme.colors.primary};
                `}
                data-id="cancel"
                type="button"
                value="Cancel"
                onClick={() => cancel()}
              />
            </Flex>
          </Flex>
        </PanelContainer>
        <Mask />
      </>
    )
  );
};

QuoteFilterForm.propTypes = {
  isFilterOpen: PropTypes.bool,
  toggleFilterPanel: PropTypes.func,
};

export default QuoteFilterForm;
